import React, { useContext, useEffect, useState } from 'react';
import Styled from './AdminEditor.styled';
import { IAdminEditSection } from './AdminEditBanner';
import Loading from 'components/Loading';
import AdminGestaoPaginaService from 'core/http/service/admin/AdminGestaoPagina.service';
import { ButtonsCustom } from 'enums/ButtonsCustomEnum';
import ActionComponent, { IvaluesActionComponent } from './components/ActionComponent';
import ModalExclusao from 'pages/gestao_paginas/new-edit-recurso/components/modal/ModalExclusao';
import TipoRedirecionamento from 'enums/TipoRedirecionamento';
import { AdminGestaoPaginasContext } from 'pages/gestao_paginas/context/AdminGestaoPaginasContext';
import { useParams } from 'react-router-dom';
import { ToastError, ToastSuccess } from 'components/Toaster';
import { isStringsValid, isValidLink } from './isValidNewContentInput';
import SelectedButtons from './components/SelectedButtons';
import Select from 'components/Select';
import AdminImageUploader from '../imageUploader/AdminImageUploader';
import BackgroundComponent from './components/BackgroundComponent';
import AllowedGroupsValidator from 'pages/admin/components/allowedGroupsValidator/AllowedGroupsValidator';
import { KcAdminGroups } from 'models/kc/KcUserInfo';
import { GradientButton, RedSolidButton } from 'components/UI/Buttons';
import { getOptionFromValue, tipoRedirecionamentoOptions } from './tipoRedirecionamento';
import AdminSecaoPaginaData, { AdminSecaoInteresse } from 'pages/gestao_paginas/AdminSecaoPaginaData';

const AdminEditInteresse = ({ onSaved, onUpdate, onDelete, mode }: IAdminEditSection) => {
    const params: any = useParams();
    const [idSecao, setIdSecao] = useState<number>(0);
    const [loading, setloading] = useState(false);

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [text, setText] = useState('');
    const [chaveInteresse, setChaveInteresse] = useState('');

    const service = new AdminGestaoPaginaService();

    const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
    const [showModalDeleteSecao, setShowModalDeleteSecao] = useState(false);
    const [modePage, setModePage] = useState<boolean>(false);

    const [submitting, setSubmitting] = useState(false);

    const { currentSection, sections, setSections } = useContext(AdminGestaoPaginasContext.Context);

    const MAX_CHAR_CONTENT = 1000;

    const [colorBackground, setColorBackground] = useState<string | null>(null);
    const [typeButton, setTypeButton] = useState<ButtonsCustom>(ButtonsCustom.Button_01);

    const [titleCustom, setTitleCustom] = useState<IvaluesActionComponent>({
        valueColorText: '#000000',
        isOculto: false,
        valueFontSize: 22,
    });

    const [descriptionSecao, setDescriptionSecao] = useState<IvaluesActionComponent>({
        valueColorText: '#000000',
        isOculto: false,
        valueFontSize: 16,
    });

    const [textoApoioSecao, setTextoApoioSecao] = useState<IvaluesActionComponent>({
        valueColorText: '#000000',
        isOculto: false,
        valueFontSize: 16,
    });

    const handleDelete = async () => {
        setShowModalDeleteSecao(true);
        setIsLoadingDelete(true);

        try {
            const response = await service.deleteSecaoSimplesComImagem(idSecao);

            if (response.status === 200) {
                ToastSuccess('Seção registro de interesse excluído com sucesso!');
                setShowModalDeleteSecao(false);
                setIsLoadingDelete(false);
                onDelete();
            }
        } catch (error) {
            ToastError('Ocorreu um erro na hora da exclusão.');
        }
    };

    const isValidInput = (): boolean => {
        return isStringsValid([title, text, description, chaveInteresse]);
    };

    const saveSection = async (section: AdminSecaoPaginaData, holder: AdminSecaoInteresse) => {
        if (!section) {
            alert('Erro ao salvar!');
            return;
        }

        const { status, data } = await service.inserirSecao(holder, section.paginaId, section.ordem);

        if (status < 300) {
            section.setCodigo(data.codigo);
            onSaved(section);
            setModePage(true);
        } else ToastError('Houve um erro ao tentar salvar o seção!');
    };

    const updateSection = async (section: AdminSecaoPaginaData, holder: AdminSecaoInteresse) => {
        if (!section) {
            alert('Erro ao atualizar!');
            return;
        }

        const { status, data } = await service.atualizarSecaoInteresse(section.codigo, holder);

        if (status < 300) {
            section.setCodigo(data.codigo);
            onUpdate(section);
        } else {
            ToastError('Houve um erro ao tentar atualizar o seção!');
        }
    };

    const submit = async () => {
        if (!currentSection) {
            alert('Erro ao salvar!');
            return;
        }
        setSubmitting(true);
        const holder = currentSection.modelData as AdminSecaoInteresse;
        holder.titulo = title;
        holder.corTitulo = titleCustom.valueColorText;
        holder.tamanhoTitulo = titleCustom.valueFontSize.toString();
        holder.ocultarTitulo = titleCustom.isOculto;
        holder.descricao = description;
        holder.corDescricao = descriptionSecao.valueColorText;
        holder.tamanhoDescricao = descriptionSecao.valueFontSize.toString();
        holder.ocultarDescricao = descriptionSecao.isOculto;
        holder.textoApoio = text;
        holder.corTextoApoio = textoApoioSecao.valueColorText;
        holder.tamanhoTextoApoio = textoApoioSecao.valueFontSize.toString();
        holder.ocultarTextoApoio = textoApoioSecao.isOculto;
        holder.chaveInteresse = chaveInteresse;
        holder.estiloBotao = typeButton;
        holder.corSecao = colorBackground ?? '#FFFFFF';

        currentSection.modelData = holder;
        setSections([...sections, currentSection]);
        if (mode == 'NEW') await saveSection(currentSection, holder);
        else await updateSection(currentSection, holder);
        setSubmitting(false);
    };

    const fetchItem = async () => {
        const id = String(params.id);
        setloading(true);

        if (mode == 'EDIT') {
            const { data } = await service.buscarSecaoInteressePorId(parseInt(id));
            setModePage(true);
            setIdSecao(parseInt(id));
            setDescription(data.descricao);
            setTitle(data.titulo);
            setText(data.textoApoio);
            setTitleCustom(prev => ({
                ...prev,
                valueColorText: data.corTitulo,
                valueFontSize: data.tamanhoTitulo,
                isOculto: data.ocultarTitulo,
            }));

            setDescriptionSecao(prev => ({
                ...prev,
                valueColorText: data.corDescricao,
                valueFontSize: data.tamanhoDescricao,
                isOculto: data.ocultarDescricao,
            }));

            setTextoApoioSecao(prev => ({
                ...prev,
                valueColorText: data.corTextoApoio,
                valueFontSize: data.tamanhoTextoApoio,
                isOculto: data.ocultarTextoApoio,
            }));

            setChaveInteresse(data.chaveInteresse);
            setColorBackground(data.corSecao);
            setTypeButton(data.estiloBotao);
        }
        setloading(false);
    };

    useEffect(() => {
        fetchItem();
    }, []);

    return (
        <>
            <Styled.Container>
                <ModalExclusao
                    showModal={showModalDeleteSecao}
                    handleHiden={setShowModalDeleteSecao}
                    handleExcluxed={handleDelete}
                    isLoading={isLoadingDelete}
                />
                {!loading ? (
                    <>
                        <div>
                            <Styled.Label>Título da Seção</Styled.Label>
                            <Styled.Input value={title} onChange={(evt: any) => setTitle(evt.target.value)} />
                            <ActionComponent
                                name={'title'}
                                valueColorText={titleCustom.valueColorText}
                                valueFontSize={titleCustom.valueFontSize}
                                isOculto={titleCustom.isOculto}
                                setColorText={value =>
                                    setTitleCustom(prevValues => ({
                                        ...prevValues,
                                        valueColorText: value,
                                    }))
                                }
                                setValueFontSize={value =>
                                    setTitleCustom(prevValues => ({
                                        ...prevValues,
                                        valueFontSize: value,
                                    }))
                                }
                                setIsOculto={value =>
                                    setTitleCustom(prevValues => ({
                                        ...prevValues,
                                        isOculto: value,
                                    }))
                                }
                            />

                            <br />
                            <Styled.Label>Descrição da Seção</Styled.Label>
                            <Styled.Input
                                value={description}
                                onChange={(evn: any) => setDescription(evn.target.value)}
                                rows={8}
                                as="textarea"
                                className={'form-control'}
                                maxLength={MAX_CHAR_CONTENT}
                            />

                            <ActionComponent
                                name={'description'}
                                valueColorText={descriptionSecao.valueColorText}
                                valueFontSize={descriptionSecao.valueFontSize}
                                isOculto={descriptionSecao.isOculto}
                                setColorText={value =>
                                    setDescriptionSecao(prevValues => ({
                                        ...prevValues,
                                        valueColorText: value,
                                    }))
                                }
                                setValueFontSize={value =>
                                    setDescriptionSecao(prevValues => ({
                                        ...prevValues,
                                        valueFontSize: value,
                                    }))
                                }
                                setIsOculto={value =>
                                    setDescriptionSecao(prevValues => ({
                                        ...prevValues,
                                        isOculto: value,
                                    }))
                                }
                            />
                            <Styled.CountLetter danger={text?.length >= MAX_CHAR_CONTENT}>
                                {text.length}/{MAX_CHAR_CONTENT}
                            </Styled.CountLetter>

                            <Styled.Label>Aparência do botão:</Styled.Label>
                            <SelectedButtons value={typeButton} setSelected={setTypeButton} ocultarButton={[1]} />

                            <Styled.Label>Chave Interesse</Styled.Label>
                            <Styled.Input value={chaveInteresse} onChange={(evt: any) => setChaveInteresse(evt.target.value)} />

                            <Styled.Label>Texto de Apoio</Styled.Label>
                            <Styled.Input
                                value={text}
                                onChange={(evn: any) => setText(evn.target.value)}
                                rows={4}
                                as="textarea"
                                className={'form-control'}
                                maxLength={MAX_CHAR_CONTENT}
                            />

                            <ActionComponent
                                name={'text_apoio'}
                                valueColorText={textoApoioSecao.valueColorText}
                                valueFontSize={textoApoioSecao.valueFontSize}
                                isOculto={textoApoioSecao.isOculto}
                                setColorText={value =>
                                    setTextoApoioSecao(prevValues => ({
                                        ...prevValues,
                                        valueColorText: value,
                                    }))
                                }
                                setValueFontSize={value =>
                                    setTextoApoioSecao(prevValues => ({
                                        ...prevValues,
                                        valueFontSize: value,
                                    }))
                                }
                                setIsOculto={value =>
                                    setTextoApoioSecao(prevValues => ({
                                        ...prevValues,
                                        isOculto: value,
                                    }))
                                }
                            />
                            <Styled.CountLetter danger={text?.length >= MAX_CHAR_CONTENT}>
                                {text.length}/{MAX_CHAR_CONTENT}
                            </Styled.CountLetter>
                        </div>

                        <Styled.RigthArea style={{ justifyContent: 'space-between' }}>
                            <BackgroundComponent
                                colorBackground={colorBackground}
                                colorDescription={descriptionSecao.valueColorText}
                                colorTitle={titleCustom.valueColorText}
                                setColor={setColorBackground}
                            />
                            <br />
                            <div style={{ gap: '1rem', display: 'flex', flexDirection: 'column' }}>
                                {modePage && (
                                    <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER]}>
                                        <RedSolidButton style={{ marginBottom: 20 }} onClick={() => setShowModalDeleteSecao(true)}>
                                            Deletar
                                        </RedSolidButton>
                                    </AllowedGroupsValidator>
                                )}
                                <GradientButton disabled={submitting || !isValidInput()} onClick={submit}>
                                    {submitting ? 'Salvando...' : 'Salvar'}
                                </GradientButton>
                            </div>
                        </Styled.RigthArea>
                    </>
                ) : (
                    <Loading />
                )}
            </Styled.Container>
        </>
    );
};

export default AdminEditInteresse;
