import { MobileMaxWidth } from 'constants/Dimensions';
import Colors from 'enums/Colors';
import styled from 'styled-components';

const StyledSecaoImagem = {
    MarginContent: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
    `,

    Container: styled.div<{ color?: string }>`
        display: flex;
        align-items: center;
        flex-direction: column;
        max-height: 1000px;
        width: 70%;
        overflow: hidden;
        padding-top: 20px;
        background-color: ${({ color }) => `${color}`};

        @media screen and (max-width: 850px) {
            width: 100%;
        }
    `,

    Title: styled.p<{ color?: string; fontSize?: string }>`
        color: #183f73;
        font-family: Barlow;
        font-style: normal;
        font-weight: bold;
        font-size: ${({ fontSize }) => fontSize + 'px'};
        color: ${({ color }) => color};
        line-height: 43px;
        text-align: center;
        width: 100%;
        margin-bottom: 20px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            max-width: 315px;
            padding-bottom: 30px;
        }
    `,

    Text: styled.p<{ color?: string; fontSize?: string }>`
        text-align: center;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: ${({ fontSize }) => fontSize + 'px'};
        line-height: 24px;
        color: ${({ color }) => color};
        width: 70%;
        margin-bottom: 20px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            font-size: 14px;
            max-width: 315px;
            text-align: center;
            width: 100%;
        }
    `,

    Button: styled.button`
        color: white;
        font-weight: 700;
        font-size: clamp(12px, 3vw, 14px);
        line-height: 18px;
        letter-spacing: 0.3px;
        min-width: 100px;
        padding: 12px 13px;
        gap: 10px;
        background: ${Colors.Blue};
        border: 1px solid ${Colors.Blue};
        border-radius: 50px;
        margin-bottom: 20px;

        &:hover {
            background: ${Colors.DarkBlue3};
            color: ${Colors.White};
            border: 1px solid ${Colors.DarkBlue3};
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            width: 250px;
            height: 100%;
            line-height: unset;
        }
    `,

    Imagem: styled.img`
        object-fit: cover;
        object-position: center;
        max-height: 700px;
        width: 100%;
    `,
};

export default StyledSecaoImagem;
