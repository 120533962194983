import React from 'react';
import Styled from './SecaoImagem.styled';
import SecaoImagemModel from 'models/generic-components/SecaoImagem';
import ButtonGeneric from '../components/buttonGeneric/ButtonGeneric';
import { ButtonsCustom } from 'enums/ButtonsCustomEnum';
import { bucketS3, caminhoAnexosGestaoPaginaSecaoImagem } from 'constants/EnvironmentData';
import GenericComponentsUtils from '../generic-components.utils';
import { useHistory } from 'react-router-dom';
import useLogged from 'hooks/useLogged';

interface ISecaoImagem {
    dados: SecaoImagemModel;
}

const SecaoImagem = ({ dados }: ISecaoImagem) => {
    const history = useHistory();
    const { authenticated } = useLogged();

    const getS3BaseUrl = (): string => {
        return bucketS3() + caminhoAnexosGestaoPaginaSecaoImagem();
    };

    const goToLink = (location: string) => {
        GenericComponentsUtils.gotoLink(location, history, authenticated, dados);
    };

    return (
        <Styled.MarginContent>
            <Styled.Container color={dados?.corSecao}>
                {!dados?.ocultarTitulo && (
                    <Styled.Title color={dados?.corTitulo} fontSize={dados?.tamanhoTitulo}>
                        {dados?.titulo}
                    </Styled.Title>
                )}
                {!dados?.ocultarTexto && (
                    <Styled.Text color={dados?.corTexto} fontSize={dados?.tamanhoTexto}>
                        {dados?.texto}
                    </Styled.Text>
                )}

                {!dados?.ocultarBotao && (
                    <ButtonGeneric
                        styled={{ margin: '10px' }}
                        name={dados.textoBotao}
                        type={dados.estiloBotao}
                        onClick={() => {
                            goToLink(dados.linkBotao ?? '');
                        }}
                    />
                )}
                <Styled.Imagem src={getS3BaseUrl() + dados?.filePath} />
            </Styled.Container>
        </Styled.MarginContent>
    );
};

export default SecaoImagem;
