import HomepageResult from 'models/homepage/HomepageResult';
import { PesquisaContext } from 'pages/home/context/PesquisaContext';
import React, { useState, useEffect, useContext, StyleHTMLAttributes, CSSProperties } from 'react';

import NoResults from './NoResults';
import ResultadoItemPesquisa from './ResultadoItemPesquisa';
import Styled from './ResultadoPesquisa.styled';
import ResultadoPesquisaPagination from './ResultadoPesquisaPagination';

export interface IPesquisaHome {}
interface ISideMenuContainer {
    active: boolean;
    text: string;
    count: number;
    style: CSSProperties;
}

const SideMenuContainer = ({ active, text, count, style }: ISideMenuContainer) => {
    return (
        <Styled.SideMenuContainer active={active} style={style}>
            <Styled.SideMenuText>{text}</Styled.SideMenuText>
            <Styled.SideCount active={active}>
                <span>{count}</span>
            </Styled.SideCount>
        </Styled.SideMenuContainer>
    );
};

const ResultadoPesquisaHome = ({}: IPesquisaHome) => {
    const ITEMS_PER_PAGE = 10;
    const [page, setPage] = useState(0);
    const { results, resultFor, isResultView } = useContext(PesquisaContext.Context);
    const [categoryActive, setCategoryActive] = useState('TODAS');

    const getCategoryLabel = (category: string) => {
        if (category == 'CURSOLIVRE') return 'Cursos Livres';
        else if (category == 'PAGINTERMADIARIAS') return 'Jornadas';
        else if (category == 'DYNAMIC_PAGE') return 'Páginas';
        else if (category == 'TRILHAS') return 'Trilhas';
        else if (category == 'SOLUCOES') return 'Soluções';
        else return category.charAt(0).toUpperCase() + category.slice(1, category.length);
    };

    const getCountMenu = (category: string): number => {
        let count = 0;

        for (let i = 0; i < results.length; i++) {
            const result = results[i];

            if (result.subcategoria == category || result.categoria == category) {
                count++;
            }
        }

        return count;
    };

    const getCategories = (): string[] => {
        const categories: string[] = [];

        for (let i = 0; i < results.length; i++) {
            const category = results[i].categoria ?? '';
            if (results[i].categoria != null && !categories.includes(category)) {
                if (category == 'BIBLIOTECA') {
                    if (!categories.includes(results[i].subcategoria ?? 'Biblioteca')) {
                        categories.push(results[i].subcategoria ?? 'Biblioteca');
                    }
                } else categories.unshift(category);
            }
        }

        return categories;
    };

    const getFilteredItems = (categoryInline?: string, limit?: number): HomepageResult[] => {
        const category = categoryInline ?? categoryActive;

        if (category == 'TODAS') return results;

        const filtered: HomepageResult[] = [];

        for (let i = 0; i < results.length; i++) {
            if (results[i].subcategoria == category || results[i].categoria == category) {
                if (limit && filtered.length >= limit) break;

                filtered.push(results[i]);
            }
        }

        return filtered;
    };

    const getFilteredItemsSliced = () => {
        const totalItems: number = getFilteredItems().length;
        const start = ITEMS_PER_PAGE * page;
        const last = Math.min(totalItems, ITEMS_PER_PAGE * (page + 1));

        console.log(start, last);

        return getFilteredItems().slice(start, last);
    };

    return (
        <Styled.Container>
            <Styled.Title>Mostrando resultados para &quot;{resultFor}&quot;</Styled.Title>
            <Styled.ContentContainer>
                <Styled.SideContainer>
                    <div onClick={() => setCategoryActive('TODAS')}>
                        <SideMenuContainer
                            count={results.length}
                            text="Todos os resultados"
                            active={categoryActive == 'TODAS'}
                            style={{ borderBottom: 'solid 2px #C9C9C9' }}
                        />
                    </div>

                    {getCategories().map(c => {
                        return (
                            <div onClick={() => setCategoryActive(c)} key={c}>
                                <SideMenuContainer count={getCountMenu(c)} text={getCategoryLabel(c)} active={categoryActive == c} style={{}} />
                            </div>
                        );
                    })}
                </Styled.SideContainer>

                {results.length == 0 && isResultView && <NoResults />}

                <>
                    {categoryActive != 'TODAS' ? (
                        <Styled.Content key={page}>
                            <Styled.TopContent>
                                <Styled.TitleContent>{getCategoryLabel(categoryActive)}</Styled.TitleContent>
                                <Styled.SubTitleContent>{getFilteredItems().length} resultados</Styled.SubTitleContent>
                            </Styled.TopContent>

                            {getFilteredItemsSliced().map((r, idx) => {
                                return <ResultadoItemPesquisa data={r} key={idx} />;
                            })}

                            {getFilteredItems().length > ITEMS_PER_PAGE && (
                                <ResultadoPesquisaPagination
                                    page={page}
                                    perPage={ITEMS_PER_PAGE}
                                    totalItems={getFilteredItems().length}
                                    onSelectPage={p => setPage(p)}
                                />
                            )}
                        </Styled.Content>
                    ) : (
                        <Styled.Content>
                            {getCategories().map((c, idx) => {
                                return (
                                    <React.Fragment key={idx}>
                                        <Styled.VerticalSpace height={idx == 0 ? 0 : 30} />
                                        <Styled.TopContent>
                                            <Styled.TitleContent>{getCategoryLabel(c)}</Styled.TitleContent>
                                            <Styled.SubTitleContent>{getFilteredItems(c).length} resultados</Styled.SubTitleContent>
                                        </Styled.TopContent>

                                        {getFilteredItems(c, 3).map((r, idx) => {
                                            return <ResultadoItemPesquisa data={r} key={idx} />;
                                        })}

                                        {getFilteredItems(c).length > 3 && (
                                            <Styled.SeeMore
                                                onClick={() => {
                                                    setCategoryActive(c);
                                                    window.scrollTo(0, 0);
                                                    setPage(0);
                                                }}
                                            >
                                                Ver mais ({getFilteredItems(c).length - 3})
                                            </Styled.SeeMore>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </Styled.Content>
                    )}
                </>
            </Styled.ContentContainer>
        </Styled.Container>
    );
};

export default ResultadoPesquisaHome;
