import React, { useEffect } from 'react';
import HomeProvider from 'pages/home/context/PesquisaProvider';
import { Helmet } from 'react-helmet';
import keycloak from 'core/config/keycloak';

import styled from 'styled-components';

import HomePage from './pages/home/Home';
import { VERSION_APPLICATION } from 'VersionApplication';

const AppContainer = styled.div({});
const App = () => {




    useEffect(() => {
        if (keycloak.authenticated) {
            const params = new URLSearchParams(window.location.search);
            const linkParam = params.get('link');
            // Lógica para quando o usuário está autenticado
            const newParams = new URLSearchParams();
            newParams.set('logged', 'true');
            newParams.set('link', linkParam ?? 'default');

            // Construir a nova URL com logged aparecendo primeiro
            const newUrl = `?${newParams.toString()}`;

            // Substituir a URL no histórico
            window.history.replaceState(null, '', newUrl);
        }
    }, [keycloak.authenticated]);

    useEffect(() => {
        console.log('VERSION: ' + VERSION_APPLICATION);
    }, []);

    return (
        <AppContainer id="homepageContainer">
            <HomePage />
        </AppContainer>
    );
};

export default App;
