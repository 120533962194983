import { MobileMaxWidth } from 'constants/Dimensions';
import { ButtonsCustom } from 'enums/ButtonsCustomEnum';
import Colors from 'enums/Colors';
import { colors } from 'react-select/src/theme';
import styled from 'styled-components';

const StyledButtonGeneric = {
    Button: styled.button<{ typeButton: ButtonsCustom }>`
        color: white;
        font-weight: 700;
        font-size: clamp(12px, 3vw, 14px);
        line-height: 18px;
        letter-spacing: 0.3px;
        min-width: 100px;
        padding: 12px 13px;
        gap: 10px;
        background: ${({ typeButton }) => (typeButton === 'BUTTON_02' ? '#FFFF' : typeButton === 'BUTTON_03' ? Colors.Yellow : Colors.Blue)};
        border: 1px solid
            ${({ typeButton }) => (typeButton === 'BUTTON_02' ? Colors.Blue : typeButton === 'BUTTON_03' ? Colors.Yellow : Colors.Blue)};
        color: ${({ typeButton }) => (typeButton !== 'BUTTON_01' ? Colors.Blue : '#FFFF')};
        border-radius: 50px;

        &:hover {
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            width: 250px;
            height: 100%;
            line-height: unset;
        }
    `,
};

export default StyledButtonGeneric;
