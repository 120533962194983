import { GenericService } from '../Generic.service';

export class CategoriaUsersPE extends GenericService {
    readonly URI: string = '/v1/categoriaUserPaginaExclusiva';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public listAll() {
        return this.get('listAll');
    }
    public listMyCategories() {
        return this.get('userCategories');
    }
}
