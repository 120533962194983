import React, { useContext, useEffect, useState } from 'react';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import ProfileData from 'models/ProfileData';

import InformacoesPessoaisForm1 from './forms/InformacoesPessoaisForm1';
import { Route, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as profileActions from '../../../../store/actions/Profile';
import UsuarioDTO from 'models/UsuarioDTO';
import { isAutoCadastrado } from 'core/util/KeycloakHelp';
import { useKeycloak } from '@react-keycloak/web';
import { ProfileContext } from 'pages/profile/context/ProfileContext';

export interface IInformacoesPessoais {}

const InformacoesPessoais = ({}: IInformacoesPessoais) => {
    const { _setPostProfilePath } = useContext(ProfileContext.Context);
    const location: any = useLocation();

    useEffect(() => {
        if (location && location?.state.postProfilePath) {
            _setPostProfilePath(location?.state.postProfilePath);
        }
    }, []);

    return (
        <>
            <Route path={'/profile/informacoes'} exact component={InformacoesPessoaisForm1} />
        </>
    );
};

export default InformacoesPessoais;
