import TipoRedirecionamento from 'enums/TipoRedirecionamento';
import { AdminModeloSecaoModelEnum } from './new-edit-pagina/AdminGestaoPaginasModeloSecaoModal';
import TipoAnexoCarrossel from 'enums/TipoAnexoCarrosselEnum';

export interface ModeloBase {
    modeloId: AdminModeloSecaoModelEnum;
    modeloNome: string;
    toObject: (paginaId: number, ordem: number) => Object;
}

class AdminSecaoPaginaData {
    public tempId: number;

    public codigo: number = -1;

    constructor(public paginaId: number, public nomeModelo: string, public ordem: number, public oculto: boolean, public modelData: ModeloBase) {
        this.tempId = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
    }

    setCodigo(codigo: number) {
        this.codigo = codigo;
    }

    get isPersist(): boolean {
        return this.codigo > 0;
    }
}

export class AdminSecaoPaginaBanner implements ModeloBase {
    public modeloId = AdminModeloSecaoModelEnum.BANNER;
    public modeloNome = 'Banner';

    constructor(
        public titulo: string,
        public descricao: string,
        public link: string,
        public textoBotaoA: string,
        public textoBotaoB: string,
        public paginaLateral: boolean,
        public tipoRedirecionamento?: TipoRedirecionamento,
        public anexoBase64?: string | null
    ) {}

    toObject(paginaId: number, ordem: number) {
        return {
            ordem,
            codigoPaginaIntermediaria: paginaId,
            tipoModeloSecao: {
                codigo: this.modeloId,
            },
            secaoBanner: {
                titulo: this.titulo,
                descricao: this.descricao,
                link: this.link,
                textoBotaoA: this.textoBotaoA,
                textoBotaoB: this.textoBotaoB,
                tipoRedirecionamento: this.tipoRedirecionamento,
                paginaLateral: this.paginaLateral,
                anexoBase64: this.anexoBase64,
            },
        };
    }
}

export class AdminSecaoPaginaImagemSimples implements ModeloBase {
    public modeloId = AdminModeloSecaoModelEnum.SIMPLES_IMAGEM_D;
    public modeloNome = 'Simples com Imagem à direita';

    constructor(
        public titulo: string,
        public descricao: string,
        public link: string,
        public textoBotaoA: string,
        public orientacao: 'D' | 'E',
        public tipoRedirecionamento?: TipoRedirecionamento,
        public anexoBase64?: string | null
    ) {
        if (orientacao == 'E') {
            this.modeloId = AdminModeloSecaoModelEnum.SIMPLES_IMAGEM_E;
            this.modeloNome = 'Simples com Imagem à esquerda';
        }
    }

    toObject(paginaId: number, ordem: number) {
        return {
            ordem,
            codigoPaginaIntermediaria: paginaId,
            tipoModeloSecao: {
                codigo: this.modeloId,
            },
            secaoSimplesImagem: {
                titulo: this.titulo,
                descricao: this.descricao,
                link: this.link,
                textoBotaoA: this.textoBotaoA,
                orientacao: this.orientacao,
                anexoBase64: this.anexoBase64,
                tipoRedirecionamento: this.tipoRedirecionamento,
            },
        };
    }
}

export class AdminSecaoImagem implements ModeloBase {
    public modeloId = AdminModeloSecaoModelEnum.SIMPLES_IMAGEM_I;
    public modeloNome = 'Simples com Imagem (I)';

    constructor(
        public titulo: string,
        public corTitulo: string,
        public tamanhoTitulo: string,
        public ocultarTitulo: boolean,
        public texto: string,
        public corTexto: string,
        public tamanhoTexto: string,
        public ocultarTexto: boolean,
        public estiloBotao: string,
        public ocultarBotao: boolean,
        public linkBotao: string,
        public textoBotao: string,
        public orientacao: 'B',
        public corSecao?: string,
        public tipoRedirecionamento?: TipoRedirecionamento,
        public anexoBase64?: string | null
    ) {}

    toObject(paginaId: number, ordem: number) {
        return {
            ordem,
            codigoPaginaIntermediaria: paginaId,
            tipoModeloSecao: {
                codigo: this.modeloId,
            },
            secaoImagem: {
                titulo: this.titulo,
                corTitulo: this.corTitulo,
                tamanhoTitulo: this.tamanhoTitulo,
                ocultarTitulo: this.ocultarTitulo,
                texto: this.texto,
                corTexto: this.corTexto,
                tamanhoTexto: this.tamanhoTexto,
                ocultarTexto: this.ocultarTexto,
                corSecao: this.corSecao,
                estiloBotao: this.estiloBotao,
                ocultarBotao: this.ocultarBotao,
                linkBotao: this.linkBotao,
                textoBotao: this.textoBotao,
                orientacao: this.orientacao,
                anexoBase64: this.anexoBase64,
                tipoRedirecionamento: this.tipoRedirecionamento,
            },
        };
    }
}

export class AdminSecaoInteresse implements ModeloBase {
    public modeloId = AdminModeloSecaoModelEnum.INTERESSE;
    public modeloNome = 'Registro de Interesse';

    constructor(
        public titulo: string,
        public corTitulo: string,
        public tamanhoTitulo: string,
        public ocultarTitulo: boolean,
        public descricao: string,
        public corDescricao: string,
        public tamanhoDescricao: string,
        public ocultarDescricao: boolean,
        public textoApoio: string,
        public corTextoApoio: string,
        public tamanhoTextoApoio: string,
        public ocultarTextoApoio: boolean,
        public chaveInteresse: string,
        public estiloBotao: string,
        public corSecao?: string
    ) {}

    toObject(paginaId: number, ordem: number) {
        return {
            ordem,
            codigoPaginaIntermediaria: paginaId,
            tipoModeloSecao: {
                codigo: this.modeloId,
            },
            secaoInteresse: {
                titulo: this.titulo,
                corTitulo: this.corTitulo,
                tamanhoTitulo: this.tamanhoTitulo,
                ocultarTitulo: this.ocultarTitulo,
                descricao: this.descricao,
                corDescricao: this.corDescricao,
                tamanhoDescricao: this.tamanhoDescricao,
                ocultarDescricao: this.ocultarDescricao,
                textoApoio: this.textoApoio,
                corTextoApoio: this.corTextoApoio,
                tamanhoTextoApoio: this.tamanhoTextoApoio,
                ocultarTextoApoio: this.ocultarTextoApoio,
                chaveInteresse: this.chaveInteresse,
                corSecao: this.corSecao,
                estiloBotao: this.estiloBotao,
            },
        };
    }
}

export class AdminSecaoPaginaSimplesTexto implements ModeloBase {
    public modeloId = AdminModeloSecaoModelEnum.SIMPLES_TEXTO;
    public modeloNome = 'Simples sem imagem';

    constructor(
        public titulo: string,
        public descricao: string,
        public link: string,
        public textoBotaoA: string,
        public tipoRedirecionamento?: TipoRedirecionamento
    ) {}

    toObject(paginaId: number, ordem: number) {
        return {
            ordem,
            codigoPaginaIntermediaria: paginaId,
            tipoModeloSecao: {
                codigo: this.modeloId,
            },
            secaoSimples: {
                titulo: this.titulo,
                descricao: this.descricao,
                link: this.link,
                textoBotaoA: this.textoBotaoA,
                tipoRedirecionamento: this.tipoRedirecionamento,
            },
        };
    }
}

export class AdminSecaoPaginaCarouselItem {
    public codigo: number = -1;

    constructor(
        public titulo: string,
        public conteudo: string,
        public ocupacao: string,
        public textoBotao: string,
        public link: string,
        public tipoRedirecionamento?: TipoRedirecionamento,
        public anexoBase64?: string,
        public oculto?: boolean
    ) {}

    toObject() {
        return {
            titulo: this.titulo,
            conteudo: this.conteudo,
            ocupacao: this.ocupacao,
            textoBotao: this.textoBotao,
            link: this.link,
            anexo: this.anexoBase64 || '',
            oculto: this.oculto,
            tipoRedirecionamento: this.tipoRedirecionamento,
        };
    }

    setCodigo(codigo: number) {
        this.codigo = codigo;
    }

    get isPersist(): boolean {
        return this.codigo > 0;
    }
}

export class AdminSecaoPaginaCarousel implements ModeloBase {
    public modeloId = AdminModeloSecaoModelEnum.CAROUSEL;
    public modeloNome = 'Carrossel';

    private itens: AdminSecaoPaginaCarouselItem[];

    constructor(public titulo: string, public conteudo: string) {
        this.itens = [];
    }

    pushItem(item: AdminSecaoPaginaCarouselItem) {
        this.itens.push(item);
    }

    toObject(paginaId: number, ordem: number) {
        return {
            ordem,
            codigoPaginaIntermediaria: paginaId,
            tipoModeloSecao: {
                codigo: this.modeloId,
            },
            secaoCarrossel: {
                titulo: this.titulo,
                descricao: this.conteudo,
                carrosseis: this.itens.map(item => item.toObject()),
            },
        };
    }
}

export type AdminSecaoPaginaCardItemCategory = {
    codigo: number;
};

export class AdminSecaoPaginaCardItem {
    public codigo: number = -1;

    constructor(
        public titulo: string,
        public conteudo: string,
        public link: string,
        public linkExterno: boolean,
        public linkInterno: string,
        public linkSaibaMais: string,
        public categoria: AdminSecaoPaginaCardItemCategory,
        public ativo: boolean,
        public desabilitado: boolean,
        public anexoBase64: string,
        public ordem: number,
        public tipoRedirecionamento?: TipoRedirecionamento
    ) {}

    toObject() {
        return {
            titulo: this.titulo,
            conteudo: this.conteudo,
            link: this.link,
            linkExterno: this.linkExterno,
            linkInterno: this.linkInterno,
            linkSaibaMais: this.linkSaibaMais,
            categoria: this.categoria,
            ativo: this.ativo,
            desabilitado: this.desabilitado,
            anexoBase64: this.anexoBase64,
            ordem: this.ordem,
            tipoRedirecionamento: this.tipoRedirecionamento,
        };
    }

    setCodigo(codigo: number) {
        this.codigo = codigo;
    }

    get isPersist(): boolean {
        return this.codigo > 0;
    }
}

export class AdminSecaoPaginaCards implements ModeloBase {
    public modeloId = AdminModeloSecaoModelEnum.CARTOES;
    public modeloNome = 'Cartões com categoria';

    private itens: AdminSecaoPaginaCardItem[];

    constructor(public titulo: string, public conteudo: string) {
        this.itens = [];
    }

    pushItem(item: AdminSecaoPaginaCardItem) {
        this.itens.push(item);
    }

    toObject(paginaId: number, ordem: number) {
        return {
            ordem,
            codigoPaginaIntermediaria: paginaId,
            tipoModeloSecao: {
                codigo: this.modeloId,
            },
            secaoCardCategoria: {
                titulo: this.titulo,
                descricao: this.conteudo,
                cards: this.itens.map(item => item.toObject()),
            },
        };
    }
}

export class AdminSecaoPaginaItensCustom implements ModeloBase {
    public modeloId = AdminModeloSecaoModelEnum.ITENS_CUSTOM;
    public modeloNome = 'Números em Destaque';

    private itens: AdminSecaoPaginaItemCustom[];

    constructor(public corSecao: string) {
        this.itens = [];
    }

    pushItem(item: AdminSecaoPaginaItemCustom) {
        this.itens.push(item);
    }

    toObject(paginaId: number, ordem: number) {
        return {
            ordem,
            codigoPaginaIntermediaria: paginaId,
            tipoModeloSecao: {
                codigo: this.modeloId,
            },
            secaoItemCustomizado: {
                corSecao: this.corSecao,
                itens: this.itens.map(item => item.toObject()),
            },
        };
    }
}

export class AdminSecaoPaginaItemCustom {
    public codigo: number = -1;

    constructor(
        public infoDestaque: string,
        public corInfoDestaque: string,
        public tamanhoInfoDestaque: number,
        public ocultarInfoDestaque: boolean,
        public detalhamento: string,
        public corDetalhamento: string,
        public tamanhoDetalhamento: number,
        public ocultarDetalhamento: boolean,
        public ocultarAnexo: boolean,
        public anexoBase64?: string,
        public oculto?: boolean
    ) {}

    toObject() {
        return {
            infoDestaque: this.infoDestaque,
            corInfoDestaque: this.corInfoDestaque,
            tamanhoInfoDestaque: this.tamanhoInfoDestaque,
            ocultarInfoDestaque: this.ocultarInfoDestaque,
            detalhamento: this.detalhamento,
            corDetalhamento: this.corDetalhamento,
            tamanhoDetalhamento: this.tamanhoDetalhamento,
            ocultarDetalhamento: this.ocultarDetalhamento,
            ocultarAnexo: this.ocultarAnexo,
            anexo: this.anexoBase64 || '',
            oculto: this.oculto,
        };
    }

    setCodigo(codigo: number) {
        this.codigo = codigo;
    }

    get isPersist(): boolean {
        return this.codigo > 0;
    }
}

export class AdminSecaoPaginaCarouselMedia implements ModeloBase {
    public modeloId = AdminModeloSecaoModelEnum.CAROUSEL_MEDIA;
    public modeloNome = 'Carrossel Multimídia';

    private itens: AdminSecaoPaginaCarouselMediaItem[];

    constructor(public corSecao: string) {
        this.itens = [];
    }

    pushItem(item: AdminSecaoPaginaCarouselMediaItem) {
        this.itens.push(item);
    }

    toObject(paginaId: number, ordem: number) {
        return {
            ordem,
            codigoPaginaIntermediaria: paginaId,
            tipoModeloSecao: {
                codigo: this.modeloId,
            },
            secaoCarrosselMultimidia: {
                corSecao: this.corSecao,
                carrosseis: this.itens.map(item => item.toObject()),
            },
        };
    }
}

export class AdminSecaoPaginaCarouselMediaItem {
    public codigo: number = -1;

    constructor(
        public detalhamento: string,
        public corDetalhamento: string,
        public tamanhoDetalhamento: number,
        public ocultarDetalhamento: boolean,
        public tipoAnexo: TipoAnexoCarrossel,
        public anexoBase64?: string,
        public urlVideo?: string,
        public oculto?: boolean
    ) {}

    toObject() {
        return {
            detalhamento: this.detalhamento,
            corDetalhamento: this.corDetalhamento,
            tamanhoDetalhamento: this.tamanhoDetalhamento,
            ocultarDetalhamento: this.ocultarDetalhamento,
            tipoAnexo: this.tipoAnexo,
            anexoBase64: this.anexoBase64 || '',
            urlVideo: this.urlVideo || '',
            oculto: this.oculto,
        };
    }

    setCodigo(codigo: number) {
        this.codigo = codigo;
    }

    get isPersist(): boolean {
        return this.codigo > 0;
    }
}

export default AdminSecaoPaginaData;
