import Loading from 'components/Loading';
import React, { useEffect, FunctionComponent, useContext, useRef, useCallback, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router';
import UsuarioDTO from 'models/UsuarioDTO';
import { RoleEnum } from 'enums/RoleEnum';
import { AccessPermissionContext } from 'core/context/AccessPermissionContext';
import useUserData from 'hooks/useUserData';
import { possuiRedirecionamentoPosLogin } from 'core/util/StorageRedirectRulesHelper';
import { useDispatch } from 'react-redux';
import { ProfileService } from 'core/http/service/Profile.service';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import * as profileActions from '../store/actions/Profile';
import Styled from './Login.styled';
import { typePendencias } from 'core/context/AccessPermissionProvider';
import FormikTextInput from './profile/components/formik/FormikTextInput';
import { MaskDate, MaskMobile } from 'core/util/MaskHelper';
import Select from 'components/Select';
import ParametrosCadastroPerfilDTO from 'models/perfil/ParametrosCadastroPerfilDTO';
import LocalidadeDTO from 'models/perfil/LocalidadeDTO';
import { DeficienciasService } from 'core/http/service/Deficiencias.service';
import TipoDeficiencia from 'models/perfil/TipoDeficiencia';
import TipoDeficienciaDTO from 'models/perfil/TipoDeficienciaDTO';
import { YellowButton } from 'components/UI/Buttons';
import PerfilAtualizationDTO from 'models/perfil/PerfilAtualizationDTO';
import { ToastError, ToastSuccess } from 'components/Toaster';
import PerfilUsuario from 'models/perfil/PerfilUsuario';
import { Toaster } from 'react-hot-toast';
import { PerfilDeficienciaService } from 'core/http/service/PerfilDeficiencia.service';
import PerfilTipoDeficienciaDTO from 'models/perfil/PerfilTipoDeficienciaDTO';
import { ValidadeHelp } from 'core/util/ValidateHelp';

interface IFormPendentes {
    pendencias: typePendencias[];
    parametrosPerfil?: ParametrosCadastroPerfilDTO;
    pathRedirect: string;
}

const initialValuesError = {
    dataNascimento: false,
    genero: false,
    cor: false,
    cpf: false,
    comoConheceu: false,
    estado: false,
    cidade: false,
    possuiDeficiencia: false,
};

const FormPendentes = ({ pendencias, parametrosPerfil, pathRedirect }: IFormPendentes) => {
    const [listGeneros, setListGeneros] = useState<any>([]);
    const [listCores, setListCores] = useState<any>([]);
    const [listComoConheceu, setListComoConheceu] = useState<any>([]);
    const [listEstado, setListEstado] = useState<any>([]);
    const [listCidades, setListCidades] = useState<any>([]);
    const [isLoadingCities, setIsLoadingCities] = useState(false);
    const [tiposDeficiencias, setTiposDeficiencias] = useState<TipoDeficiencia[]>([]);
    const [selectedDeficiencias, setSelectedDeficiencias] = useState<TipoDeficienciaDTO[]>([]);
    const [otherDeficiencia, setOtherDeficiencia] = useState<boolean>(false);
    const [statusAtt, setStatusAtt] = useState<boolean>(false);
    const { loadData } = useUserData();

    const history = useHistory();

    const profileService = new ProfileService();
    const deficienciasService = new DeficienciasService();
    const perfilDeficienciaService = new PerfilDeficienciaService();

    const [values, setValues] = useState({
        dataNascimento: '',
        genero: -1,
        cpf: '',
        cor: -1,
        comoConheceu: -1,
        estado: '',
        cidade: '',
        possuiDeficiencia: -1,
        whatsapp: '',
    });

    const [valuesError, setValuesError] = useState(initialValuesError);

    useEffect(() => {
        let listGeneros = new Array();
        let listCores = new Array();
        let listComoConheceu = new Array();
        let listEstado = new Array();

        if (parametrosPerfil) {
            for (const genero of parametrosPerfil.listaGeneros) {
                listGeneros.push({ label: genero.descricao, value: genero.id });
            }

            for (const cor of parametrosPerfil.listaCores) {
                listCores.push({ label: cor.descricao, value: cor.id });
            }

            for (const comoConheceu of parametrosPerfil.listaComoConheceu) {
                listComoConheceu.push({ label: comoConheceu.descricao, value: comoConheceu.id });
            }

            for (const estado of parametrosPerfil.listaEstados) {
                listEstado.push({ label: estado.nome, value: estado.id });
            }
        }

        setListGeneros(listGeneros);
        setListCores(listCores);
        setListComoConheceu(listComoConheceu);
        setListEstado(listEstado);
    }, [parametrosPerfil]);

    const setErrorGlobal = (params: any) => {
        setValuesError(prevState => ({ ...prevState, ...params }));
    };

    const validationForm = (perfil?: PerfilUsuario): boolean => {
        setValuesError(initialValuesError);
        let contentError = false;

        const setError = (params: any) => {
            setValuesError(prevState => ({ ...prevState, ...params }));
            contentError = true;
        };

        if (values.dataNascimento === '' && pendencias.includes(typePendencias.DATA_NASCIMENTO)) setError({ dataNascimento: true });
        if (values.genero === -1 && pendencias.includes(typePendencias.GENERO)) setError({ genero: true });
        if (pendencias.includes(typePendencias.CPF)) {
            if (values.cpf !== '') {
                if (!ValidadeHelp.validarCPF(values.cpf)) {
                    setError({ cpf: true });
                }
            } else {
                setError({ cpf: true });
            }
        }
        if (values.cor === -1 && pendencias.includes(typePendencias.COR)) setError({ cor: true });
        if (values.comoConheceu === -1 && pendencias.includes(typePendencias.COMO_CONHECEU)) setError({ comoConheceu: true });
        if (values.estado === '' && pendencias.includes(typePendencias.ESTADO)) setError({ estado: true });
        if (values.cidade === '' && pendencias.includes(typePendencias.CIDADE)) setError({ cidade: true });
        if (values.possuiDeficiencia === 1 && pendencias.includes(typePendencias.DEFICIENCIAS)) {
            if (selectedDeficiencias.length === 0) {
                setError({ possuiDeficiencia: true });
            } else {
                selectedDeficiencias.map((d: TipoDeficienciaDTO) => {
                    if (d.codigoTipoDeficiencia === 7) {
                        if (d.descricao === '' || d.descricao === null) setError({ possuiDeficiencia: true });
                    }
                });
            }
        } else if (values.possuiDeficiencia === -1 && pendencias.includes(typePendencias.DEFICIENCIAS)) setError({ possuiDeficiencia: true });

        return contentError ? false : true;
    };

    const handleSubmit = async () => {
        const { perfilUsuario } = await loadData();
        setStatusAtt(true);

        if (!validationForm()) {
            setStatusAtt(false);
            return;
        }

        if (perfilUsuario) {
            const perfil: PerfilAtualizationDTO = new PerfilAtualizationDTO();
            perfil.username = perfilUsuario?.username ?? '';
            perfil.dataNascimento = values.dataNascimento;
            perfil.cor = values.cor;
            perfil.cpf = values.cpf;
            perfil.genero = values.genero;
            perfil.estado = values.estado.toString() === '' ? perfilUsuario?.estado ?? '' : values.estado.toString();
            perfil.cidade = values.cidade.toString() === '' ? perfilUsuario?.cidade ?? '' : values.cidade.toString();
            perfil.whatsapp = values.whatsapp;
            perfil.comoConheceu = values.comoConheceu;

            try {
                const response = await profileService.atualizarPendenciasPerfil(perfil);

                if (response.status === 200) {
                    if (values.possuiDeficiencia !== -1) {
                        const deficiencias: PerfilTipoDeficienciaDTO = new PerfilTipoDeficienciaDTO();

                        deficiencias.codigoPerfil = perfilUsuario.codigo;
                        deficiencias.deficiencias = selectedDeficiencias;

                        await perfilDeficienciaService.insert(deficiencias);
                    }

                    setTimeout(() => {
                        history.push(pathRedirect);
                    }, 2000);
                }
            } catch (error) {
                console.log('🚀 ~ handleSubmit ~ error:', error);
                ToastError('Erro no servidor ou/ CPF já existente!');
                setStatusAtt(false);
            }
        }
        setStatusAtt(false);
    };

    const loadCidadesPorUf = async (uf: string) => {
        try {
            setIsLoadingCities(true);
            setValues({ ...values, estado: uf, cidade: '' });
            const response: any = await profileService.consultarCidadesPorUF(uf);
            const _cidades: LocalidadeDTO[] = response.data.map((item: any) => new LocalidadeDTO().fromJSON(item));
            const _cidadesDto = new Array();

            for (const cidade of _cidades) {
                _cidadesDto.push({ label: cidade.nome, value: cidade.nome });
            }

            setListCidades(_cidadesDto);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoadingCities(false);
        }
    };

    const fetchDeficiencias = async () => {
        const { data } = await deficienciasService.listarAll();

        if (data) setTiposDeficiencias(data);
    };

    const handleCheckboxChange = (deficiencia: TipoDeficiencia, isChecked: boolean) => {
        const tipoDeficienciaInstance = new TipoDeficienciaDTO();
        tipoDeficienciaInstance.codigoTipoDeficiencia = deficiencia.id;

        if (isChecked) {
            setSelectedDeficiencias(
                prevSelected =>
                    prevSelected.filter(
                        selected => selected.codigoTipoDeficiencia !== tipoDeficienciaInstance.codigoTipoDeficiencia
                    ) as TipoDeficienciaDTO[]
            );
        } else {
            setSelectedDeficiencias(prevSelected => [...prevSelected, tipoDeficienciaInstance]);
        }

        if (tipoDeficienciaInstance.codigoTipoDeficiencia === 7 && !isChecked) {
            setOtherDeficiencia(true);
        } else if (tipoDeficienciaInstance.codigoTipoDeficiencia === 7 && isChecked) {
            setOtherDeficiencia(false);
        }
    };

    const handleDescription = (value: string) => {
        const novaLista: TipoDeficienciaDTO[] = selectedDeficiencias.map((obj: TipoDeficienciaDTO) => {
            if (obj.codigoTipoDeficiencia === 7) {
                return { ...obj, descricao: value };
            }
            return obj;
        }) as TipoDeficienciaDTO[];

        setSelectedDeficiencias(novaLista);
    };

    useEffect(() => {
        if (pendencias.includes(typePendencias.CPF)) {
            if (values.cpf !== '' && values.cpf.length > 10) {
                if (!ValidadeHelp.validarCPF(values.cpf)) {
                    setErrorGlobal({ cpf: true });
                } else {
                    setErrorGlobal({ cpf: false });
                }
            }
        }
    }, [values.cpf]);

    useEffect(() => {
        fetchDeficiencias();
    }, [parametrosPerfil]);

    const rendererForm = (type: typePendencias) => {
        switch (type) {
            case typePendencias.CPF: {
                return (
                    <Styled.Column error={valuesError.cpf}>
                        <Styled.Row>
                            <Styled.Label>Informe seu CPF:</Styled.Label>
                            <Styled.InputText
                                error={false}
                                name="cpf"
                                placeholder=""
                                value={values.cpf}
                                onChange={(evt: any) => {
                                    const value: string = evt.target.value;
                                    setValues({ ...values, cpf: value });
                                }}
                            />
                        </Styled.Row>
                        {valuesError.cpf && (
                            <p
                                style={{
                                    color: '#d90429',
                                    fontWeight: 'bold',
                                    marginLeft: '10px',
                                }}
                            >
                                {values.cpf !== '' && 'CPF inválido. Por favor, insira um CPF válido.'}
                            </p>
                        )}
                    </Styled.Column>
                );
            }
            case typePendencias.COMO_CONHECEU: {
                return (
                    <Styled.Row error={valuesError.comoConheceu}>
                        <Styled.Label>Como conheceu a humane?</Styled.Label>
                        <Select
                            width="250px"
                            isSmall={true}
                            itens={listComoConheceu}
                            onSelected={selected => {
                                setValues({
                                    ...values,
                                    comoConheceu: parseInt(selected.value.toString()),
                                });
                            }}
                        />
                    </Styled.Row>
                );
            }
            case typePendencias.DATA_NASCIMENTO: {
                return (
                    <Styled.Row error={valuesError.dataNascimento}>
                        <Styled.Label>Data de nascimento: </Styled.Label>
                        <Styled.InputText
                            error={false}
                            name="dataNascimento"
                            placeholder="DD/MM/AAAA"
                            value={values.dataNascimento}
                            onChange={(evt: any) => {
                                const value: string = evt.target.value;
                                const newDate = values['dataNascimento'].endsWith('/') ? value : MaskDate(value);
                                setValues({ ...values, dataNascimento: newDate });
                            }}
                        />
                    </Styled.Row>
                );
            }
            case typePendencias.WHATSAPP: {
                return (
                    <Styled.Column>
                        <Styled.Row>
                            <Styled.Label>WhatsApp: </Styled.Label>
                            <Styled.InputText
                                error={false}
                                name="whatsApp"
                                placeholder="(00) 00000-0000"
                                value={values.whatsapp}
                                onChange={(evt: any) => {
                                    const value: string = evt.target.value;
                                    const numberFormatado = MaskMobile(value);
                                    setValues({ ...values, whatsapp: numberFormatado });
                                }}
                            />
                        </Styled.Row>
                        <span
                            style={{
                                marginLeft: '160px',
                                fontStyle: 'italic',
                                fontSize: 14,
                            }}
                        >
                            Este campo é opcional
                        </span>
                    </Styled.Column>
                );
            }
            case typePendencias.GENERO: {
                return (
                    <Styled.Row error={valuesError.genero} style={{ marginTop: '-1px' }}>
                        <Styled.Label>Gênero: </Styled.Label>
                        <Select
                            width="250px"
                            isSmall={true}
                            itens={listGeneros}
                            onSelected={selected => {
                                setValues({
                                    ...values,
                                    genero: parseInt(selected.value.toString()),
                                });
                            }}
                        />
                    </Styled.Row>
                );
            }
            case typePendencias.COR: {
                return (
                    <Styled.Row error={valuesError.cor} style={{ marginTop: '-1px' }}>
                        <Styled.Label>Cor: </Styled.Label>
                        <Select
                            width="250px"
                            isSmall={true}
                            itens={listCores}
                            onSelected={selected => {
                                setValues({
                                    ...values,
                                    cor: parseInt(selected.value.toString()),
                                });
                            }}
                        />
                    </Styled.Row>
                );
            }

            case typePendencias.ESTADO: {
                return (
                    <Styled.Row error={valuesError.estado} style={{ marginTop: '-1px' }}>
                        <Styled.Label>Estado de atuação: </Styled.Label>
                        <Select
                            width="250px"
                            isSmall={true}
                            itens={listEstado}
                            onSelected={selected => {
                                loadCidadesPorUf(selected.value.toString());
                            }}
                        />
                    </Styled.Row>
                );
            }
            case typePendencias.CIDADE: {
                return (
                    <Styled.Row error={valuesError.cidade} style={{ marginTop: '-1px' }}>
                        <Styled.Label>Cidade de atuação: </Styled.Label>
                        <Select
                            width="250px"
                            isSmall={true}
                            disabled={isLoadingCities || values.estado === ''}
                            itens={listCidades}
                            value={values.cidade !== '' ? listCidades.find((city: any) => city.id === values.cidade) : null}
                            onSelected={selected => {
                                setValues({
                                    ...values,
                                    cidade: selected.value.toString(),
                                });
                            }}
                        />
                    </Styled.Row>
                );
            }
            case typePendencias.DEFICIENCIAS: {
                return (
                    <>
                        <Styled.Row error={valuesError.possuiDeficiencia} style={{ marginTop: '-1px' }}>
                            <Styled.Label>Você possui algum tipo de Deficiência?</Styled.Label>
                            <Select
                                width="250px"
                                isSmall={true}
                                itens={[
                                    { label: 'Sim', value: 1 },
                                    { label: 'Não', value: 0 },
                                ]}
                                onSelected={selected => {
                                    setValues({
                                        ...values,
                                        possuiDeficiencia: selected.value ? 1 : 0,
                                    });
                                }}
                            />
                        </Styled.Row>
                        {values.possuiDeficiencia === 1 && (
                            <Styled.Column error={valuesError.possuiDeficiencia}>
                                <Styled.Label>Informe qual o tipo de deficiência você possui:</Styled.Label>

                                <Styled.TwoColumns>
                                    {tiposDeficiencias.map((e, idx) => {
                                        const isChecked = selectedDeficiencias.some(deficiencia => deficiencia.codigoTipoDeficiencia === e.id);

                                        return (
                                            <Styled.Row key={e.id}>
                                                <Styled.CheckBox
                                                    type="checkbox"
                                                    name="deficiencia"
                                                    id={`deficiencia-${idx}`}
                                                    checked={isChecked}
                                                    onChange={evt => handleCheckboxChange(e, isChecked)}
                                                />
                                                <Styled.LabelCheck htmlFor={`deficiencia-${idx}`}>{e.descricao}</Styled.LabelCheck>
                                            </Styled.Row>
                                        );
                                    })}
                                </Styled.TwoColumns>
                                {otherDeficiencia && (
                                    <Styled.Row>
                                        <Styled.Label>Informe qual é a deficiência:</Styled.Label>
                                        <Styled.Column>
                                            <Styled.InputText
                                                error={false}
                                                name="otherDeficiencia"
                                                placeholder="Descreva"
                                                value={
                                                    selectedDeficiencias.length > 0
                                                        ? selectedDeficiencias.filter(s => s.codigoTipoDeficiencia === 7).map(s => s.descricao)
                                                        : ''
                                                }
                                                onChange={(evt: any) => {
                                                    handleDescription(evt.target.value);
                                                }}
                                            />
                                            <label style={{ marginTop: '5px' }}>Digite no mínimo 5 caractéres</label>
                                        </Styled.Column>
                                    </Styled.Row>
                                )}
                            </Styled.Column>
                        )}
                    </>
                );
            }
        }
    };

    return (
        <Styled.Container>
            <Styled.Title>Informações Pendentes</Styled.Title>
            <Styled.Subtitle>Complete seu perfil para continuar navegando na plataforma.</Styled.Subtitle>
            {pendencias.map((p: any) => rendererForm(p))}
            <YellowButton disabled={statusAtt} onClick={handleSubmit}>
                {statusAtt ? 'Atualizando...' : 'Atualizar perfil'}
            </YellowButton>
        </Styled.Container>
    );
};

const Login: FunctionComponent = ({ ...props }) => {
    const { redirectBehindRegister, pendencias, parametrosPerfil, pathRedirect } = useContext(AccessPermissionContext.Context);

    const hiddenButtonRef = useRef<HTMLButtonElement>(null);
    const profileService = new ProfileService();
    const isLoggedin: Boolean = useAppSelector(state => state.profileReducer.isLoggedin);

    const { loadData } = useUserData();
    const history = useHistory();
    const location: any = useLocation();
    const dispatch = useDispatch();

    const getPathToRedirect = () => {
        const currLocationHref: String = window.location.href;
        const currHrefSplit: string[] = currLocationHref.split('?');
        let pathToRedirect: string = '';
        if (currHrefSplit.length > 1) {
            pathToRedirect = currHrefSplit[1];
        }

        if (pathToRedirect && pathToRedirect.length > 0) {
            return pathToRedirect;
        } else if (location && location.state && location.state.pathToRedirect && location.state.pathToRedirect.length > 0) {
            return location.state.pathToRedirect;
        }
        return pathToRedirect;
    };

    const initialize = async () => {
        const { usuarioLogado, perfilUsuario } = await loadData();
        if (usuarioLogado) {
            if (await isAdmin(usuarioLogado)) {
                history.push('/admin');
            } else if (getPathToRedirect().length && getPathToRedirect() !== '/login') {
                redirectBehindRegister(getPathToRedirect());
            } else if (possuiRedirecionamentoPosLogin() && perfilUsuario) {
                //redirecionarCursoLivrePosLogin(hiddenButtonRef);
                if (perfilUsuario?.codigo === -1) {
                    history.push('/profile/informacoes');
                } else {
                    redirectBehindRegister('/');
                }
            } else {
                if (perfilUsuario?.codigo === -1) {
                    history.push('/profile/informacoes');
                } else {
                    redirectBehindRegister('/');
                }
            }

            if (!isLoggedin && perfilUsuario?.codigo) {
                registrarLogLogin(perfilUsuario.codigo);
                dispatch(profileActions.setIsLogaded(true));
            }
        }
    };

    const registrarLogLogin = async (idPerfil: number) => {
        if (idPerfil > 0) {
            await profileService.registrandoLogin(idPerfil);
        }
        return;
    };

    const isAdmin = async (usuarioLogado: UsuarioDTO) => {
        let retorno: boolean = false;
        usuarioLogado.roles?.forEach(role => {
            if (role === RoleEnum.Administrador) {
                retorno = true;
            }
        });
        return retorno;
    };

    useEffect(() => {
        initialize();
    }, []);

    return (
        <>
            <button ref={hiddenButtonRef} style={{ display: 'none' }}></button>
            {isLoggedin && pendencias && pendencias?.length > 0 ? (
                <FormPendentes pendencias={pendencias} parametrosPerfil={parametrosPerfil} pathRedirect={pathRedirect} />
            ) : (
                <Loading />
            )}
        </>
    );
};

export default Login;
