import { MobileMaxWidth } from 'constants/Dimensions';
import Colors from 'enums/Colors';
import styled from 'styled-components';

interface IArrowButton {
    next: boolean;
}

const StyledMultiSolucoes = {
    Container: styled.div<{ backgroundColor?: Colors; height?: string }>`
        width: 100%;
        background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : Colors.Blue)};
        height: 1020px;

          @media screen and (max-width: 1200px) {
            height: 1120px;
          }
          @media screen and (max-width: 800px) {
            height: 1150px;
          }

        @media screen and (max-width: 480px) {
            height: ${({ height }) => height};
        }
    `,

    Header: styled.div<{ padding?: string; preview?: boolean }>`
        display: flex;
        flex-direction: column;
        padding-left: ${({ padding }) => (padding ? padding : '70px')};
        padding-top: 70px;
        gap: 1rem;

        @media screen and (max-width: 720px) {
            padding: ${({ preview }) => (preview ? '70px 0px 0px 0px' : '20px')};
            align-items: center;
        }
    `,

    Title: styled.h1`
        font-size: 32px;
        color: #ffffff;
        font-weight: bold;
    `,

    Description: styled.p`
        max-width: 65%;
        font-size: 20px;
        line-height: 28px;
        color: #ffffff;

        @media screen and (max-width: 720px) {
            max-width: 100%;
            width: 100%;
        }
    `,

    Row: styled.div`
        display: flex;
        gap: 1rem;
    `,

    ButtonPrimary: styled.button<{ light?: boolean }>`
        background-color: ${({ light }) => (light ? Colors.White : Colors.Blue)};
        color: ${({ light }) => (light ? Colors.Blue : Colors.White)};
        font-weight: 600;
        width: 280px;
        min-height: 50px;
        border-radius: 10px;
        border: none;
        margin-top: 10px;

        &:hover {
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
    `,

    Carrousel: styled.div`
        display: flex;
        height: 450px;
        margin: 40px;
    `,

    CarouselCardContainer: styled.div<{ preview?: boolean }>`
        width: 100%;
        margin-top: 90px;
        padding: 10px;
        padding-left: ${({ preview }) => (preview ? '60px' : '30px')};

        .slick-slide {
            display: flex;
            justify-content: start;
            align-items: start;
        }

        .slick-dots {
            li {
                button::before {
                    color: ${({ preview }) => (preview ? Colors.Blue : Colors.Yellow)}; /* Define a cor das dots */
                    font-size: 20px;
                }
                &.slick-active {
                    button::before {
                        color: ${({ preview }) => (preview ? Colors.Blue : Colors.Yellow)}; /* Define a cor para a dot selecionada */
                    }
                }
            }
        }

        @media screen and (max-width: 800px) {
            padding-left: 0px;
            .slick-slide {
                justify-content: center;
                align-items: center;
            }
        }
    `,

    Dots: styled.div<{ preview?: boolean }>`
        width: 20px;
        height: 20px;
        margin: 10px;
        border-radius: 100%;
    `,

    SvgIcon: styled.svg.attrs({
        version: '1.1',
        xmlns: 'http://www.w3.org/2000/svg',
        xmlnsXlink: 'http://www.w3.org/1999/xlink',
    })`
        height: 56px;
        width: 56px;
        fill: transparent;

        &:hover {
            fill: ${Colors.Yellow};
        }

        &:hover path {
            stroke: ${Colors.White};
        }
    `,

    SvgIconPreview: styled.svg.attrs({
        version: '1.1',
        xmlns: 'http://www.w3.org/2000/svg',
        xmlnsXlink: 'http://www.w3.org/1999/xlink',
    })`
        height: 56px;
        width: 56px;
        fill: transparent;

        &:hover {
            fill: ${Colors.Blue};
        }

        &:hover path {
            stroke: ${Colors.White};
        }
    `,

    ArrowButton: styled.div<IArrowButton>`
        cursor: pointer;
        width: 56px;
        height: 56px;
        outline: none;
        border: none;
        position: absolute;
        z-index: 8;
        top: 45%;
        right: ${props => (props.next ? '0%' : '')};
        left: ${props => (props.next ? '90%' : '-3%')};

        @media screen and (max-width: 1920px) {
            left: ${props => (props.next ? '98%' : '-4%')};
        }
        @media screen and (max-width: 1500px) {
            left: ${props => (props.next ? '98%' : '-4%')};
        }
        @media screen and (max-width: 1280px) {
            left: ${props => (props.next ? '100%' : '-5%')};
        }

        @media screen and (max-width: 500px) {
            left: ${props => (props.next ? '100%' : '-14%')};
        }
    `,
};

export default StyledMultiSolucoes;
