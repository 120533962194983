import React, { useEffect, useState } from 'react';
import Styled from './AdminPagination.styled';

import arrowIcon from '../../../../images/admin/arrow.svg';

export interface IAdminPagination {
    total?: number;
    withLetters?: boolean;
    onChangePage: (page: number) => void;
    resetPage?: string;
}

const AdminPagination = ({ total = 5, onChangePage, withLetters, resetPage }: IAdminPagination) => {
    const [page, setPage] = useState<number>(1);
    const [pageRange, setPageRange] = useState<number[]>([]);

    const maxPagesToShow = 10;

    const nextPage = () => {
        if (page < total) setPage(page + 1);
    };

    const prevPage = () => {
        if (page > 1) setPage(page - 1);
    };

    useEffect(() => {
        onChangePage(page);
    }, [page]);

    useEffect(() => {
        setPage(1);
    }, [total]);

    useEffect(() => {
        const start = Math.max(1, page - Math.floor(maxPagesToShow / 2));
        const end = Math.min(total, start + maxPagesToShow - 1);

        const newPageRange = Array.from({ length: end - start + 1 }, (_, idx) => start + idx);
        setPageRange(newPageRange);
    }, [page, total]);

    useEffect(() => {
        setPage(1);
    }, [resetPage]);

    return (
        <Styled.Container>
            {withLetters && (
                <Styled.DropDownContainer>
                    <span>A-Z</span>
                </Styled.DropDownContainer>
            )}

            <Styled.ArrowButtonPage onClick={prevPage} disabled={page === 1}>
                <img src={arrowIcon} />
            </Styled.ArrowButtonPage>

            {pageRange[0] > 1 && (
                <>
                    <Styled.ButtonPage onClick={() => setPage(1)}>1</Styled.ButtonPage>
                    {pageRange[0] > 2 && <Styled.Ellipsis>...</Styled.Ellipsis>}
                </>
            )}

            {pageRange.map(pageNum => (
                <Styled.ButtonPage selected={pageNum === page} key={pageNum} onClick={() => setPage(pageNum)}>
                    {pageNum}
                </Styled.ButtonPage>
            ))}

            {pageRange[pageRange.length - 1] < total && (
                <>
                    {pageRange[pageRange.length - 1] < total - 1 && <Styled.Ellipsis>...</Styled.Ellipsis>}
                    <Styled.ButtonPage onClick={() => setPage(total)}>{total}</Styled.ButtonPage>
                </>
            )}

            <Styled.ArrowButtonPage onClick={nextPage} disabled={page === total}>
                <img src={arrowIcon} style={{ transform: 'scaleX(-1)' }} />
            </Styled.ArrowButtonPage>
        </Styled.Container>
    );
};

export default AdminPagination;
