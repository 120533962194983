import Colors from 'enums/Colors';
import React from 'react';
import styled from 'styled-components';
import Img01 from './assets/img_01.png';
import Img02 from './assets/regua_logos_horizontal.png';
import { BlueSolidButton, OutlineButton, PrimaryButton } from 'components/UI/Buttons';
import { IsCustomMaxWidth } from 'core/util/WindowSize';
import { useHistory } from 'react-router-dom';

const Styled = {
    Container: styled.div`
        min-height: 620px;
        color: ${Colors.Blue};
        padding: 50px 10%;

        @media screen and (max-width: 760px) {
            display: flex;
            padding: 50px 20px;
            flex-direction: column;
        }
    `,

    Row: styled.div`
        display: flex;
        margin-top: 44px;
        gap: 1rem;
    `,

    Body: styled.div`
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 800px) {
            flex-direction: column;
            gap: 1rem;
            justify-content: center;
        }
    `,

    Content: styled.div`
        display: flex;
        flex-direction: column;
        gap: 1rem;
    `,
};

const MaterialFree = () => {
    const history = useHistory();

    return (
        <Styled.Container>
            <Styled.Body>
                <Styled.Content style={{ width: IsCustomMaxWidth(800) ? '100%' : '40%' }}>
                    <img src={Img01} height={'520px'} width={IsCustomMaxWidth(800) ? '100%' : '460px'} alt="" />
                    <img src={Img02} width={IsCustomMaxWidth(800) ? '100%' : '460px'}  alt="" />
                </Styled.Content>
                <Styled.Content style={{ width: IsCustomMaxWidth(800) ? '100%' : '40%' }}>
                    <h1 style={{ color: Colors.Blue, fontSize: 26 }}>MATERIAL GRATUITO</h1>
                    <h1 style={{ color: Colors.Blue, fontSize: 26, fontWeight: 700 }}>
                        Alfabetizar: das definições às <br /> boas práticas
                    </h1>
                    <p style={{ lineHeight: '28px', fontSize: 20 }}>
                        Neste e-book apresentamos de formas concisa uma visão <br /> abrangente e atualizada dos diversos aspectos envolvidos{' '}
                        <br /> no processo de alfabetização no Brasil.
                        <br /> <br /> Disponível para download e também na versão com acessibilidade.
                    </p>
                    <Styled.Row>
                        <BlueSolidButton onClick={() => history.push('/biblioteca/item/alfabetizar-das-definicoes-as-boas-praticas')}>
                            Baixar material
                        </BlueSolidButton>
                        <OutlineButton onClick={() => history.push('/ebooks/alfabetizacao-integral-da-teoria-a-pratica-az')}>
                            Versão acessível
                        </OutlineButton>
                    </Styled.Row>
                </Styled.Content>
            </Styled.Body>
        </Styled.Container>
    );
};

export default MaterialFree;
