import React, { useEffect, useState } from 'react';
import Styled from './Promoter.styled';
import Logo from './assets/Frame_2049.svg';
import { IoCalendarOutline } from 'react-icons/io5';
import { TbClockHour4 } from 'react-icons/tb';
import { MdComputer, MdInsertLink, MdArrowForward } from 'react-icons/md';
import { YellowButton } from 'components/UI/Buttons';
import { MdOutlineArrowForward } from 'react-icons/md';
import { MobileMaxWidth } from 'constants/Dimensions';
import useLogged from 'hooks/useLogged';
import { useHistory } from 'react-router-dom';
import GenericComponentsUtils from 'pages/generic-components/generic-components.utils';
import TipoRedirecionamento from 'enums/TipoRedirecionamento';
import BannerParceirosVertical from './assets/banner_parceiros_vertical.png';
import BannerParceirosHorizontal from './assets/banner_parceiros_horizontal2.png';
import { IsCustomMaxWidth } from 'core/util/WindowSize';
import { fetchStorageFile } from 'hooks/StoreFiles';
import Colors from 'enums/Colors';

const dates = [
    {
        month: 'Março',
        dates: '21/03',
    },
    {
        month: 'Abril',
        dates: '04/04 e 18/04',
    },
    {
        month: 'Maio',
        dates: '02/05, 16/05 e 28/05',
    },
    {
        month: 'Junho',
        dates: '13/06 e 27/06',
    },
    {
        month: 'Agosto',
        dates: '08/08  e 15/08',
    },
];

const InformationDates = () => {
    return (
        <Styled.Info.Container>
            <Styled.Info.Title>
                <IoCalendarOutline color="#ffca00" /> Confira as datas dos encontros:
            </Styled.Info.Title>
            {dates.map((date, index) => (
                <div style={{ marginLeft: '25px' }} key={index}>
                    <p>
                        <strong>{date.month}:</strong> {date.dates}
                    </p>
                </div>
            ))}
            <Styled.Info.Details>
                <Styled.Info.Content>
                    <TbClockHour4 size={20} color="#ffca00" /> Início: 17:30 Término 19:00
                </Styled.Info.Content>
                <Styled.Info.Content>
                    {' '}
                    <MdComputer size={20} color="#ffca00" /> Online
                </Styled.Info.Content>
            </Styled.Info.Details>
        </Styled.Info.Container>
    );
};

const Promoter = () => {
    const [isMobile, setIsMobile] = useState(false);
    const history = useHistory();
    const { authenticated } = useLogged();

    useEffect(() => {
        window.scrollTo(0, 0);
        setIsMobile(window.screen.width <= MobileMaxWidth);

        window.addEventListener('resize', () => {
            setIsMobile(window.screen.width <= MobileMaxWidth);
        });

        return () => window.removeEventListener('resize', () => {});
    }, []);

    const goToLink = () => {
        window.open('https://institutoayrtonsenna.org.br/mini-curso-alfabetizacao-catedra/');
    };

    const goToLinkParceiros = () => {
        const path = ' https://cursoslivres.institutoayrtonsenna.org.br/d2l/home/7004';
        GenericComponentsUtils.gotoLink(path, history, authenticated, { tipoRedirecionamento: TipoRedirecionamento.LOGIN });
    };

    return (
        <Styled.Container>
            <Styled.Img src={Logo} />
            <Styled.Content>
                <Styled.Title>{'Um curso para promover a Alfabetização'.toLocaleUpperCase()}</Styled.Title>
                <Styled.SubTitle>
                    Conheça nossa nova ação para promover e defender a alfabetização: o curso Alfabetização Integral, ofertado pela Cátedra
                    Instituto Ayrton Senna de Inovação em Avaliação Educacional. Ele traz 10 temas fundamentais para apoiar a alfabetização das
                    crianças brasileiras e grandes nomes do cenário da educação, como Maria Helena Guimarães e Castro, Inês Miskalo, Roxane
                    Rojo e Kátia Smole, entre outros. Os encontros acontecerão <strong>quinzenalmente, a partir do dia 21 de março</strong> .{' '}
                    <br /> <br /> Você é nosso convidado especial. Participe!
                </Styled.SubTitle>
                <Styled.Row isMobile={isMobile}>
                    <div>
                        <InformationDates />

                        <YellowButton
                            onClick={goToLink}
                            style={{
                                width: '300px',
                                height: '42px',
                                maxHeight: '42px',
                                fontSize: '15px',
                                padding: '8px 22px 8px 22px',
                                gap: '0.5rem',
                                marginTop: '24px',
                            }}
                        >
                            Acesse aqui <MdArrowForward size={24} />
                        </YellowButton>
                    </div>
                    <Styled.CardExclusive.Container isMobile={isMobile}>
                        <Styled.CardExclusive.Title>EXCLUSIVO PARA PARCEIROS DA CÁTEDRA:</Styled.CardExclusive.Title>
                        <Styled.CardExclusive.Description>
                            Se você é parceiro da Cátedra, clique no botão abaixo para acessar e realizar as atividades da formação assíncrona.
                        </Styled.CardExclusive.Description>
                        <Styled.CardExclusive.Center>
                            <Styled.CardExclusive.ButtonBlue onClick={goToLinkParceiros} isMobile={isMobile}>
                                Acesse aqui <MdOutlineArrowForward size={18} />
                            </Styled.CardExclusive.ButtonBlue>
                        </Styled.CardExclusive.Center>
                    </Styled.CardExclusive.Container>
                </Styled.Row>
                {IsCustomMaxWidth(1200) ? (
                    <Styled.ImgRegua src={fetchStorageFile('anexo_storage_files_10513028150915861588.png_2024-12-05-1733415722235.png')} />
                ) : (
                    <Styled.ImgRegua
                        style={{ height: 380 }}
                        src={fetchStorageFile('anexo_storage_files_11440298433490101985.png_2024-12-06-1733484426567.png')}
                    />
                )}
            </Styled.Content>
        </Styled.Container>
    );
};

export default Promoter;
