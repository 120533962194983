import React from 'react';
import Styled from './ActionComponent.styled';
import { Form } from 'react-bootstrap';

export interface IvaluesActionComponent {
    valueColorText: string;
    isOculto: boolean;
    valueFontSize: number;
}

export interface IActionComponent {
    valueColorText: string;
    isOculto: boolean;
    valueFontSize: number;
    name: string;

    setColorText: (value: string) => void;
    setIsOculto: (value: boolean) => void;
    setValueFontSize: (value: number) => void;
}

const ActionComponent = ({ valueColorText, isOculto, valueFontSize, name, setColorText, setIsOculto, setValueFontSize }: IActionComponent) => {
    return (
        <Styled.Container>
            <Styled.Action>
                <Styled.Label>Cor do texto: </Styled.Label>
                <Styled.ColorPicker type="color" value={valueColorText} onChange={e => setColorText(e.target.value)} />
            </Styled.Action>
            <Styled.Action>
                <Styled.Label>Ocultar: </Styled.Label>
                <Form.Check name={name} checked={isOculto} onChange={e => setIsOculto(e.target.checked)} type="switch" id={name} />
            </Styled.Action>
            <Styled.Action>
                <Styled.Label>Tamanho da fonte: </Styled.Label>
                <Styled.InputTamanhoFont value={valueFontSize} onChange={e => setValueFontSize(parseInt(e.target.value))} type="number" />
            </Styled.Action>
        </Styled.Container>
    );
};

export default ActionComponent;
