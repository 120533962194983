import styled from 'styled-components';

const AdminPaginationStyled = {
    Container: styled.div`
        width: 100%;

        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 100%;
    `,

    ButtonPage: styled.button<{ selected?: boolean }>`
        width: 38px;
        height: 32px;
        outline: none;
        border: 1.5px #003c78 solid;
        border-radius: 5px;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        margin: 7px 5px;

        background-color: ${props => (props.selected ? '#003c78' : 'white')};
        color: ${props => (!props.selected ? '#003c78' : 'white')};
    `,

    ArrowButtonPage: styled.button`
        width: 38px;
        height: 32px;
        outline: none;
        border-radius: 5px;
        text-align: center;
        margin: 7px 5px;
        border: none;
        background-color: white;
        vertical-align: middle;

        border: 1.5px #747474 solid;
    `,

    DropDownContainer: styled.div`
        background: #003c78;
        border-radius: 6px;
        color: white;

        display: flex;
        justify-content: center;
        align-items: center;

        width: 70px;
        height: 32px;

        transform: translateY(7px);
    `,
    Ellipsis: styled.span`
        padding: 0 4px;
        color: #999;
        font-size: 14px;
    `,
};

export default AdminPaginationStyled;
